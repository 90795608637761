import React from 'react'
import moment from 'moment'
import styled from '@emotion/styled'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';


const Wrapper = styled.div`
  margin: 0 auto 2em;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  span {
    margin: 0 0.5rem;
  }
`

const PostDate = styled.p`
  display: inline-block;
`
const Update = styled.p`
  display: inline-block;
`

const ReadingTime = styled.p`
  display: inline-block;
`

const dateFormatter = function(dateIso8601){
  var dt = new Date(dateIso8601).toLocaleDateString('ja-JP')
  return dt;
}

const tagarray = new Array("yukaia_net")

const PostDetails = props => {
  return (
    <Wrapper>
      <PostDate>📅 <time>{dateFormatter(props.date)}</time> 公開</PostDate>
      <span>•</span>
      <Update>🔄 <time>{dateFormatter(props.update)}</time> 更新</Update>
      <span>•</span>
      <ReadingTime>{`⏱️${props.timeToRead} min read `}</ReadingTime>
      <span>•</span>
      <TwitterShareButton title={props.title+ "\n"} hashtags={tagarray} url={"https://yukaia.net/" + props.slug}>
          <TwitterIcon size={30} round />
        </TwitterShareButton>
      <span></span>
        <FacebookShareButton url={"https://yukaia.net/" + props.slug} quote={props.title}>
          <FacebookIcon size={30} round />
        </FacebookShareButton>
    </Wrapper>
  )
}

export default PostDetails
