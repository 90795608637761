import React from 'react'
import styled from '@emotion/styled'

const WrapperDisqus = styled.section`
  margin: 0 auto 5em;
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  padding: 3em 1.5em 2em;
  flex-grow: 1;
  color: #5d627b;
  background: white;
  border-top: solid 5px #5d627b;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.22);
  
`

const ContainerDisqus = props => {
  return <WrapperDisqus>{props.children}</WrapperDisqus>
}

export default ContainerDisqus
